import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Uudetnettikasinot extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
			links[i].rel = 'noopener';
        }
    }
    var lisa = document.getElementsByClassName("tab-lisa");
		var bonus = document.getElementsByClassName("tab-bonus");
		var i;
		for (i = 0; i < lisa.length; i++) {
			lisa[i].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.nextElementSibling.classList.remove("active");
				}
			});
		}
		var j;
		for (j = 0; j < bonus.length; j++) {
			bonus[j].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.previousElementSibling.classList.remove("active");
				}
			});
		}					
		var tahdet = document.getElementsByClassName("tahdet");
		var t;
		for (t = 0; t < tahdet.length; t++) {
			var two = "<img src='/images/star2.webp' alt='2' class='lazyload'/>"
			var twohalf = "<img src='/images/star2half.webp' alt='kaksi' class='lazyload'/>"
			var three = "<img src='/images/star3.webp' alt='kolme' class='lazyload'/>"
			var threehalf = "<img src='/images/star3half.webp' alt='kolme' class='lazyload'/>"
			var four = "<img src='/images/star4.webp' alt='neljä' class='lazyload'/>"
			var fourhalf = "<img src='/images/star4half.webp' alt='neljä' class='lazyload'/>"
			var five = "<img src='/images/star5.webp' alt='viisi' class='lazyload'/>"
			var rate = tahdet[t].innerHTML;
			var star = rate.replace("2", two).replace("Kaksi puoli", twohalf).replace("3", three).replace("Kolme puoli", threehalf).replace("4", four).replace("Neljä puoli", fourhalf).replace("5", five);
			tahdet[t].innerHTML = star;
		}
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "uudet-nettikasinot"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
			kasinonListanimi
			bonus
			ilmaiskierrokset
			tahtiarvostelu
      logonTiedostonimi
			listalogo {
				title
				file {
					url
				}
			}
			afflink
            bonustiedotUudetKasinot {
              id
              json
            }
            kasinonListiedotUudetKasinot {
              id
              json
            }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        alaosanTeksti3 {
          json
        }
        }
      }
      `}
    render = {data => (
    <Layout>
        <GatsbySeo
      title='Uudet kasinot | Kasinosivu.com'
      description='Uusia kasinoita'
      
    />
      <Container>
        <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
        <div className="ylaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["fi-FI"].url}`}
              alt={node.data.target.fields.title["fi-FI"]}
            />
          ),
        },
        })}</div>
        <table>
          <tbody>
          {data.contentfulMoneysivujenOsiot.kasinolista.map(({listalogo,logonTiedostonimi,kasinonListanimi,tahtiarvostelu,bonus,ilmaiskierrokset,afflink,kasinonListiedotUudetKasinot,bonustiedotUudetKasinot}) => (
			<tr className={casinolistStyle.casino}>
				<td className={casinolistStyle.top}>
					<div className={casinolistStyle.logo}>
						<img data-src={`/images/logo/${logonTiedostonimi}`} alt={listalogo.title} className="lazyload" width="120" height="50"/>
						<div className={casinolistStyle.name}>
							<h5 className={casinolistStyle.title}>{kasinonListanimi}</h5>
							<span className={`${casinolistStyle.rate} tahdet`}>{tahtiarvostelu}</span>
						</div>
					</div>
					<div className={casinolistStyle.content}>
						<div className={casinolistStyle.contentBox}>
							<div className={casinolistStyle.contentInfo}>
								<div className={casinolistStyle.offerItem}>
									<i>Bonukset:</i>
									<h4>{bonus}</h4>
								</div>
								<div className={casinolistStyle.offerItem}>
									<i>Ilmaiskierrokset:</i>
									<h4>{ilmaiskierrokset}</h4>
								</div>
							</div>
							<div className={casinolistStyle.contentBtn}>
								<a className={casinolistStyle.btn} href={afflink} target="_blank">Kasinolle</a>
							</div>
						</div>
					</div>
				</td>
				<td className={casinolistStyle.bottom}>
					<div className={casinolistStyle.pikaexpand}>
						<div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
						<div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
					</div>
					<div className={casinolistStyle.expandContent}>
						<div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(kasinonListiedotUudetKasinot.json)}</div>
						<div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(bonustiedotUudetKasinot.json)}</div>
					</div>
				</td>
			</tr>
            ))}
          </tbody>
        </table>
        <div className="alaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["fi-FI"].url}`}
              alt={node.data.target.fields.title["fi-FI"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["fi-FI"].url}`}
              alt={node.data.target.fields.title["fi-FI"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti3.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["fi-FI"].url}`}
              alt={node.data.target.fields.title["fi-FI"]}
            />
          ),
        },
      })}</div>
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Uudetnettikasinot